<template>
    <div class="activity-container">
      <h2 class="active-title">{{userInfo.name}} 三科统编教材中部四盟市教师专项培训报告</h2>
      <p class="active-desc"><span class="blue">{{userInfo.name}}</span> ，你好！经过一学期的学习，你一定有很多收获吧，快来看看智慧研修平台为你的学习表现带来的全方位解读吧！在这里，你将清晰了解到自己在哪些学习内容上表现优秀，哪些内容上还需要加强提升。希望专业的报告能为你以后的学习指明方向，祝你取得更大的进步！</p>
      <h3>一、整体情况</h3>
      <p> 你一共学习了<span class="blue">{{info.courseCount || 0}}</span>个课程<span class="blue" v-if="info.courseCount">，非常棒</span> ；
          完成了<span class="blue">{{info.workCount || 0}}</span> 次作业 <span class="blue" v-if="info.workCount">，非常棒</span>；
          参加了<span class="blue">{{info.testCount || 0}}</span>次普通话测评<span class="blue" v-if="info.testCount">，非常棒</span>。
      </p>
      <div class="chart-box flex-align">
        <div :id="`myChart-Custom`"
             :style="{ width: '405px', height: '451px', margin: '0 auto' }">
        </div>
        <div class="radarInfo">
          <span v-for="(item,name) in radarInfo" :key="name">{{`${nameMap[name]}：${item}%`}}</span>
        </div>
      </div>
      <h3>二、普通话测评情况</h3>
      <p>你一共参加了 <span class="blue">{{info.testCount || 0 }}</span> 次普通话测评，测评结果如下： </p>
       <div class="chart-box flex-align">
        <div :id="`myChart-Custom1`"
             :style="{ width: '405px', height: '451px', margin: '0 auto' }">
        </div>
        <div class="radarInfo">
          <h5>通用语言能力测试-后测</h5>
          <p>{{testDetail.sumBehind || 0}}分
            <i :class="[testDetail.sumDiff > 0 ?'el-icon-top':'el-icon-bottom']" 
               :style="{'color':testDetail.sumDiff > 0 ?'red' : 'green'}"
               v-if="testDetail.sumBehind">
              {{testDetail.sumDiff}}
            </i>
         </p>
          <span v-for="(item,name) in  testBehindObj" :key="name">{{`${testNameMap[name]}:${item}分`}} 
            <i :class="[diffCount[name]> 0 ? 'el-icon-top':'el-icon-bottom']" 
               :style="{color: diffCount[name]> 0 ?'red':'green'}">
               {{`${diffCount[name]}分`}}
            </i>
          </span>
        </div>
      </div>
      <h3>三、课程学习情况</h3>
      <p>你一共学习了<span class="blue">{{info.courseCount || 0}}</span>个课程，课程出勤率为<span class="blue">{{info.courseScore || 0}}%</span>。具体情况如下表所示：</p>
       <el-table :data="courseData" border style="width: 100%">
             <el-table-column
               prop="name"
               label="课程名称"
               width="267"
               align="center"
             >
             </el-table-column>
             <el-table-column
               prop="childrenName"
               label="课节名称"
               width="265"
               align="center"
             >   
             </el-table-column>
             <el-table-column
               prop="isActive"
               label="是否出勤/观看"
               width="265"
               align="center"
             >
              <template slot-scope="scope">
                 <span :class="{'isActive': scope.row.isActive === 0 }"> {{scope.row.isActive == 0 ? "缺勤" : "出勤"}}</span>
              </template>
             </el-table-column>
             <el-table-column
               prop="count"
               label="学习时长"
               width="265"
               align="center"
             >
              <template slot-scope="scope">
               {{`${scope.row.count}分钟`}}
              </template>
             </el-table-column>
        </el-table>
      <h3>四、作业完成情况</h3>
      <p>你一共完成了<span class="blue">{{info.workCount || 0}}</span>份作业，作业提交率为<span class="blue">{{info.workScore || 0}}</span>%。具体情况如下表所示：</p>
        <el-table :data="workData" border style="width: 100%">
             <el-table-column
               prop="name"
               label="作业主题名称"
               width="267"
               align="center"
             >
             </el-table-column>
             <el-table-column
               prop="childrenName"
               label="作业名称"
               width="265"
               align="center"
             >
             </el-table-column>
             <el-table-column
               prop="isActive"
               label="是否完成"
               width="265"
               align="center"
             >
              <template slot-scope="scope">
                <span :class="{'isActive': scope.row.isActive === 0 }">{{scope.row.isActive == 0 ? "未完成" : "已完成"}}</span>
              </template>
             </el-table-column>
             <el-table-column
               prop="count"
               label="得分"
               width="265"
               align="center"
             >
             <template slot-scope="scope">
               {{scope.row.count == 0 ? '--' : scope.row.count}}
              </template>
             </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    data(){
        return {
            info:{}, //报告全部数据
            userInfo:{}, //用户信息
            courseData:[ //课程表格数据
                // {name:'乐智阅读',project:'名校联盟双师课堂',attendent:'已出勤',time:'30分钟'},
                // {name:'XYF_项目课直播_2020.12.02',project:'名校联盟双师课堂',attendent:'已出勤',time:'59分钟'},
                // {name:'2020-12-4测试直播课',project:'名校联盟双师课堂',attendent:'已出勤',time:'24分钟'},
                // {name:'陈煜123',project:'名校联盟双师课堂',attendent:'已出勤',time:'1小时10分钟'},
                // {name:'跑来跑去的KODU',project:'名校联盟双师课堂',attendent:'已出勤',time:'40分钟'},
                // {name:'静杰同步课测试',project:'名校联盟双师课堂',attendent:'已出勤',time:'40分钟'},
                // {name:'周五同步课测试',project:'名校联盟双师课堂',attendent:'未出勤',time:'0分钟'},
            ],
            workData:[ //作业表格数据
                // {name:'测试作业',project:'名校联盟双师课堂',work:'课堂作业一',finish:'已完成'},
                // {name:'测试作业数据',project:'名校联盟双师课堂',work:'课堂作业一',finish:'已完成'},
                // {name:'测试作业数据',project:'名校联盟双师课堂',work:'课堂作业二',finish:'已完成'},
                // {name:'学会理解文字含义',project:'名校联盟双师课堂',work:'课堂作业一',finish:'已完成'},
                // {name:'陈煜333',project:'名校联盟双师课堂',work:'课堂作业一',finish:'已完成'},
            ],
            wholeValue:[0,0,0,0,0], //整体情况数据value
            radarInfo:{ //整体情况雷达图数据
              courseScore:0,
              workUploadScore:0,
              activeScore:0,
              workScore: 0,
              behindScore: 0
            },
            nameMap:{ //整体情况数据中文对照表
              courseScore:'课程出勤率',
              workUploadScore:'作业提交率',
              activeScore:'参与积极性',
              workScore: '作业得分率',
              behindScore: '普通话后测得分率'
            },
            testDetail:{}, //测试总数据
            testBehindObj:{}, //后测数据
            testFrontObj:{}, //前测数据
            diffCount:{}, //涨幅数据
            testChartBehindValue:[], //后测分数数组
            testChartFrontValue:[], //前测分数数组
            testNameMap:{ //测试对象中文对应表
              multitScore:'多音节字词得分',
              questionScore:'命题说话得分',
              readScore:'朗读短文得分',
              singleScore:'单音节字词得分',
            },
            
            

        }
    },
    methods:{
        // 获取报告数据
        async getOverAll() {
            let resData = await this.$Api.Course.getOverAll();
            // console.log(resData);
            if(!resData.data) {
              this.drawWholeRadar();
              this.drawTestRadar();
              return
            }
            this.info = resData.data
            // 作业表格 数据
            this.workData = resData.data.workDetail.charts
            // 课程表格 数据
            this.courseData = resData.data.courseDetail.charts
            // 测试图标 数据
            this.testDetail = resData.data.testDetail
            this.testBehindObj = resData.data.testDetail.behindData;
            this.testFrontObj = resData.data.testDetail.frontData;
            this.diffCount = resData.data.testDetail.diffData;
            // 后测成绩
            for (const key in resData.data.testDetail.behindData) {
              this.testChartBehindValue.push( ((resData.data.testDetail.behindData[key] / resData.data.testDetail.sumData[key])*100).toFixed(2) )
            }
            // 前测成绩
            for (const key in resData.data.testDetail.frontData) {
              this.testChartFrontValue.push(((resData.data.testDetail.frontData[key] / resData.data.testDetail.sumData[key])*100).toFixed(2))
            }
            // 整体情况 成绩
            for (const key in this.info) {
              for(const item in this.radarInfo){
                if(item === key) {
                  this.radarInfo[item] = this.info[key]
                }
                if(item === 'behindScore') {
                  this.radarInfo['behindScore'] = resData.data.testDetail.sumBehind
                }
              }
            }
            // 整体情况 分数数组
            this.wholeValue = [];
            this.wholeValue.push(this.info.courseScore)
            this.wholeValue.push(this.info.workUploadScore)
            this.wholeValue.push(this.info.activeScore)
            this.wholeValue.push(this.info.workScore)
            this.wholeValue.push(this.radarInfo.behindScore)
            
            this.drawWholeRadar();
            this.drawTestRadar();
        },
        // 绘制整体情况雷达图
        drawWholeRadar() {
            let option = {}
            let myChartCustom = this.$echarts.init(document.getElementById(`myChart-Custom`));
            myChartCustom.setOption(this.defaultOption());
            myChartCustom.setOption(option = {
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                      let string = `${params.seriesName} </br>`
                      option.radar.indicator.forEach((item,index)=>{
                        string += `${item.text}: ${params.value[index]} % </br>`;
                      })
                      return string
                    }
                },
                radar: {
                    indicator:  [
                      {
                          text: '课程出勤率',
                          max:100
                      },
                      {
                          text: '作业提交率',
                          max:100
                      },
                      {
                          text: '参与积极性',
                          max:100
                      },
                      {
                          text: '作业得分率',
                          max:100
                      },
                      {
                          text: '普通话测后得分率',
                          max:100
                      },
                    ],
                },
                series: [{
                    name: '整体情况',
                    data: [{
                        value: this.wholeValue,
                        areaStyle: {
                             opacity: 0.7,
                            color: "#ADAFE4"
                        }
                    },]
                }]
            })
        },
        // 绘制普通话测评雷达图
        drawTestRadar() {
            let myChartCustom = this.$echarts.init(
                document.getElementById(`myChart-Custom1`)
            );
            let option = {}
            myChartCustom.setOption(this.defaultOption());
            myChartCustom.setOption(option = {
                legend: {
                    data: ['通用语言能力测试-前测', '通用语言能力测试-后测']
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                      let string = `${params.name} </br>`
                      option.radar.indicator.forEach((item,index)=>{
                        string += `${item.text}: ${params.value[index]} % </br>`;
                      })
                      return string
                    }
                },
                radar: {
                    indicator:  [
                      {
                          text: '单音节字词得分率',
                          max:100
                      },
                      {
                          text: '多音节词语得分率',
                          max:100
                      },
                      {
                          text: '朗读短文得分率',
                          max:100
                      },
                      {
                          text: '命题说话得分率',
                          max:100
                      }
                    ],
                },
                series: [{
                    name: '通用语言能力测试',
                    data: [{
                        value: this.testChartFrontValue ,
                        name:'通用语言能力测试-前测',
                        areaStyle: {
                           opacity: 0.5,
                           color: "#FEC7D3"
                        }
                    },
                    {
                        value: this.testChartBehindValue,
                        name:'通用语言能力测试-后测',
                        areaStyle: {
                            opacity: 0.7,
                            color: "#ADAFE4"
                        }
                    },
                    ]
                }]
            })
        },
        defaultOption() {
          const defaultOption =  {
                radar: {
                    center: ['50%', '50%'],
                    radius: 120
                },
                series: [{
                    type: 'radar'
                }]
            }
            return defaultOption
        }
    },
    mounted(){
        // this.userInfo = JSON.parse(sessionStorage.getItem('userinfo'))
        // this.getOverAll();
    },
    activated(){
      // this.userInfo = JSON.parse(sessionStorage.getItem('userinfo'))
      this.userInfo = this.$store.state.user.userInfo
      this.getOverAll();
    }
}
</script>

<style lang="less">
/*媒体查询*/
/*当页面大于1200px 时，大屏幕，主要是PC 端*/
@media (min-width: 1200px) {
  .breadcrumb {
    width: 1200px;
  }
  .activity-container {
    width: 1200px;
    margin: 0 auto;
  }
}
/*在992 和1199 像素之间的屏幕里，中等屏幕，分辨率低的PC*/
@media (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb {
    width: 950px;
  }
  .activity-container {
    width: 950px;
    margin: 0 auto;
  }
}
.activity-detail {
    margin: 0 auto;
    height: auto;
    background-color: #fafafa ;
    overflow: hidden;
    min-height: 500px;
    
    .breadcrumb {
        margin: 0 auto 31px;
        padding-top: 51px;
    }
    
    .activity-container {
        box-sizing: border-box;
        margin-bottom: 50px;
        border-radius: 10px;
        padding: 35px 68px;
        background-color:#fff;

        
        .active-title {
            font-size: 28px;
            margin-bottom: 20px;
            text-align: center;
            font-weight: bold;
        }
        .active-desc {
            padding: 25px 70px;
            background-color:#fff;
            border-radius: 10px;
        }
        .active-subtitle {
            font-size: 16px;
            color: #858585;
            margin-bottom: 10px;
            span:first-child {
                margin-right: 10px;
            }
        }
        
        .cover-box {
            margin: 20px auto;
            width: 450px;
            .cover {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }   
        }

        .blue {
            color: #2373EC;
            
        }
        
        h3 {
            font-size: 20px;
            font-weight: bold;
            margin:30px 0 15px;
        }

        p {
            text-indent:2em;
            font-size: 18px;
            line-height: 34px;
            margin:10px 0;
        }
        .explain,.time {
            margin: 50px 0;
            font-size: 16px;
            text-align: center;
        }

        .btns {
           text-align: center;
           .btn {
               line-height: 35px;
               width: 200px;
            //    padding: 0 75px;
               border-radius: 8px;
               background-color: #2373EC;
               box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
               color: #fff;
           } 
           .btn-signUp{
               border: 1px solid #2373EC;
               color: #2373EC;
               background-color: #fff;
           }
        }
    }
    .radarInfo {
      width: 450px;
      // height: 150px;
      background-color: #F8FBFE;
      padding: 50px 25px;
      box-sizing: border-box;
      span {
        display: inline-block;
        font-size: 16px;
        min-width: 45%;
        padding: 0 10px;
        margin-bottom: 20px;
      }
      h5 {
        font-size: 20px;
        text-align: center;
      }
      p {
        text-align: center;
        text-indent: 0;
      }
    }

    .isActive {
      color: #FF0000;
    }

} 


</style>